module.exports = {
  pawn: {
    white: "https://upload.wikimedia.org/wikipedia/commons/4/45/Chess_plt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Chess_pdt45.svg"
  },
  rook: {
    white: "https://upload.wikimedia.org/wikipedia/commons/7/72/Chess_rlt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Chess_rdt45.svg"
  },
  knight: {
    white: "https://upload.wikimedia.org/wikipedia/commons/7/70/Chess_nlt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Chess_ndt45.svg"
  },
  bishop: {
    white: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Chess_blt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/9/98/Chess_bdt45.svg"
  },
  king: {
    white: "https://upload.wikimedia.org/wikipedia/commons/4/42/Chess_klt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Chess_kdt45.svg"
  },
  queen: {
    white: "https://upload.wikimedia.org/wikipedia/commons/1/15/Chess_qlt45.svg",
    black: "https://upload.wikimedia.org/wikipedia/commons/4/47/Chess_qdt45.svg"
  }
}